import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import colors from "react-multi-date-picker/plugins/colors";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const SucWithdraw = () => {
  const [user, setUser] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);
  const [findByStatus, setFindByStatus] = useState("Pending");
  const [checkagain, setCheckAgain] = useState(false);
  const [clickedUsers, setClickedUsers] = useState([]); // State to store clicked users' IDs
  const [usercheckstatus, setUserCheckstatus] = useState(null);
  const [activeButton, setActiveButton] = useState("Pending");
  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  //   searching handler
  const searchHandler = (event) => {
    let key = event.target.value;
    setSearchList(key);
  };
  //   search by status handler
  const searchByStatus = (event) => {
    let key = event.target.value;
    setFindByStatus(key);
  };

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `set-txn-send/withdraw-admin/all-user-by-admin?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&_status=${findByStatus}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data.data);
        console.log(res.data.data.referenceId);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
        //console.log(res.data.data)
        const data = res.data.data;
        if (Array.isArray(data)) {
          data.forEach((item) => {
            setUserCheckstatus(item.referenceId);
          });
          setNumberOfPages(res.data.totalPages);
        } else {
          console.error("Expected data to be an array");
        }
      });
  };

  const [withdrawSuccess, setWithdrawSuccess] = useState();
  const [withdrawFail, setWithdrawFail] = useState();
  const [clickedId, setClickedId] = useState();

  function generatePaymentReference() {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 90000000) + 10000000;
    return timestamp.toString() + randomNum.toString();
  }

  // const handlePaymentStatusResponse = async (id, paymentRef) => {
  //     // Fetch payment status
  //     await axios.post(`${baseUrl}payment-status`, { paymentref: paymentRef })
  //         .then((paymentStatusResponse) => {
  //             console.log("Payment status response:", paymentStatusResponse.data);
  //             if (paymentStatusResponse.data.Status === "Hold" ) {
  //                 setCheckAgain(true)
  //                 console.log("check  pament status again")
  //             } else if(paymentStatusResponse.data.Status === "Success") {
  //                 setCheckAgain(false)
  //                 localStorage.removeItem("paymentRef")
  //                 // Update user withdraw status to "SUCCESS"
  //                 const access_token = localStorage.getItem("token");
  //                 const headers = {
  //                     Authorization: `Bearer ${access_token}`
  //                 };
  //                 axios.post(`${baseUrl}userwithdrawupdate/${id}`, { status: "SUCCESS" }, { headers })
  //                     .then((res) => {
  //                         console.log("User withdraw status updated:", res.data);
  //                         alert('Payout successfully done');
  //                     })
  //                     .catch((error) => {
  //                         console.error('Error updating user withdraw status:', error.message);
  //                         alert('Error updating user withdraw status');
  //                     });
  //             }else if(paymentStatusResponse.data.Status === "Refunded"){
  //                 setCheckAgain(false)
  //                   localStorage.removeItem("paymentRef")
  //                 alert("Refunded")
  //             }else{
  //                 setCheckAgain(false)
  //                   localStorage.removeItem("paymentRef")
  //                 console.log("payment not successful")
  //             }
  //         })
  //         .catch((error) => {
  //             console.error("Error fetching payment status:", error.message);
  //             alert('Error fetching payment status');
  //         });
  // };

  // const handlePaymentStatusResponse = async (id) => {
  //     // Fetch payment status
  //     let getPayRef = localStorage.getItem(`payRef${id}`)
  //     console.log(getPayRef)
  //     await axios.post(`${baseUrl}payment-status`, { paymentref: getPayRef })
  //         .then((paymentStatusResponse) => {
  //             console.log("Payment status response:", paymentStatusResponse.data);
  //             if (paymentStatusResponse.data.Status === "Hold") {
  //                 setCheckAgain(true)
  //                 console.log("Check payment status again");
  //             } else if (paymentStatusResponse.data.Status === "Success") {
  //                 setCheckAgain(false);
  //                 localStorage.removeItem(`payRef${id}`);
  //                 // Update user withdraw status to "SUCCESS"
  //                 const access_token = localStorage.getItem("token");
  //                 const headers = {
  //                     Authorization: `Bearer ${access_token}`
  //                 };
  //                 axios.post(`${baseUrl}userwithdrawupdate/${id}`, { status: "SUCCESS" }, { headers })
  //                     .then((res) => {
  //                         profle();
  //                         alert('Payout successfully done');
  //                         console.log("User withdraw status updated:", res.data);
  //                     })
  //                     .catch((error) => {
  //                         console.error('Error updating user withdraw status:', error.message);
  //                         alert('Error updating user withdraw status');
  //                     });
  //             } else if (paymentStatusResponse.data.Status === "Refunded") {
  //                 setCheckAgain(false);
  //                 localStorage.removeItem(`payRef${id}`);
  //                 alert("Refunded");
  //             } else {
  //                 setCheckAgain(false);
  //                 // localStorage.removeItem(`payRef${id}`);
  //                 console.log("Payment not successful");
  //             }
  //             setClickedUsers(prevClickedUsers => [...prevClickedUsers, id]);

  //         })
  //         .catch((error) => {
  //             console.error("Error fetching payment status:", error.message);
  //             alert('Error fetching payment status');
  //         });

  //     setClickedUsers(prevClickedUsers => [...prevClickedUsers, id]);

  // };

  // const handlePaymentStatusResponse = async (id) => {
  //     // Fetch payment status
  //     let getPayRef = localStorage.getItem(`payRef${id}`)
  //     console.log(getPayRef)
  //     const response = await axios.get(`https://payment.vtludoclub.com/payout/KvmCheckStatus.php?referenceId=${id}`)
  //     alert(response.data.message);
  //     console.log("response CheckStatus data:", response.data);

  // };

  // const handlePaymentStatusResponse = async (id) => {
  //     // Fetch payment status
  //     let getPayRef = localStorage.getItem(`payRef${id}`);
  //     console.log(getPayRef);
  //     try {
  //         const response = await axios.get(`https://payment.vtludoclub.com/payout/KvmCheckStatus.php?referenceId=${id}`);
  //         alert(response.data.message);
  //         console.log("response CheckStatus data:", response.data);
  //     } catch (error) {
  //         console.error("Error fetching payment status:", error);
  //         alert("Failed to fetch payment status. Please try again.");
  //     }
  // };

  // const handleCheckPaymentStatusResponse = async (id) => {
  //     const paymentStatusUrl = `https://payment.vtludoclub.com/payout/KvmCheckStatus.php?referenceId=${id}`;
  //     window.location.href = paymentStatusUrl;
  // };

  // const withdrowPass = async (id, amt, mobileNumber) => {
  //     const generatedPayRef = generatePaymentReference()
  //     localStorage.setItem(`payRef${id}`, generatedPayRef);
  //     console.log("from withdraw pass", generatedPayRef)
  //     setClickedId(id)
  //     const confirm = window.confirm("Are you sure you want to update this payout to success?");
  //     if (confirm) {
  //         const response = await axios.get(`https://payment.vtludoclub.com/payout/kvmPayout.php?amount=${amt}&mobile=${mobileNumber}&id=${id}`);
  //         alert("response.data.message");
  //         console.log("response data:", response.data);
  //         console.log("statustxt", response.data.status);
  //         console.log("payout ref from server", response.data.payout_refno);
  //         if (response.data.status === 'true') {
  //             handlePaymentStatusResponse(id)
  //         }
  //     }
  // };

  // const withdrowPass = async (id, amt, mobileNumber) => {
  //     const generatedPayRef = generatePaymentReference();
  //     localStorage.setItem(`payRef${id}`, generatedPayRef);
  //     console.log("from withdraw pass", generatedPayRef);
  //     setClickedId(id);

  //     const confirm = window.confirm("Are you sure you want to update this payout to success?");
  //     if (confirm) {
  //         try {
  //             // const response = await axios.get(`https://payment.vtludoclub.com/payout/kvmPayout.php?amount=${amt}&mobile=${mobileNumber}&id=${id}`);
  //             // alert(response.data.message);
  //             // console.warn("response data message:", response.data.message);
  //             // console.log("statustxt", response.data.status);
  //             // console.log("payout ref from server", response.data.payout_refno);
  //             // if (response.data.status === 'true') {
  //             //     await handlePaymentStatusResponse(id);
  //             // }
  //             window.location.reload();
  //         } catch (error) {
  //             console.error("Error during payout process:", error);
  //             alert("Payout process failed. Please try again.");
  //         }
  //     }
  // };

  const withdrowPass = async (
    id,
    amt,
    mobileNumber,
    userName,
    acc_no,
    ifsc
  ) => {
    const generatedPayRef = generatePaymentReference();
    localStorage.setItem(`payRef${id}`, generatedPayRef);
    console.log("from withdraw pass", generatedPayRef);
    setClickedId(id);
    const confirm = window.confirm(
      "Are you sure you want to update this payout to success?"
    );
    if (confirm) {
      window.location.href = `https://payment.vtludoclub.com/payout/kvmPayout.php?amount=${amt}&mobile=${mobileNumber}&id=${id}`;
    }
  };

  let getPaymentRef = localStorage.getItem("paymentref");
  // console.log(getPaymentRef)

  const withdrowFail = (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to failed this payout?"
    );
    if (confirm) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `hase-user-withdraw-update-hshs/${id}`,
          {
            status: "FAILED",
          },
          { headers }
        )
        .then((res) => {
          profle();
          alert("Payout successfully reject");
          //console.log(res);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  const withdrowSuccess = (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to Success this payout?"
    );
    if (confirm) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `hase-user-withdraw-update-hshs/${id}`,
          {
            status: "SUCCESS",
          },
          { headers }
        )
        .then((res) => {
          profle();
          alert("Payout successfully Accepted");
          //console.log(res);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  const universalCheckPayout = (payment_gatway, txn_id, referenceId) => {
    //alert(payment_gatway);
    if (!referenceId) {
      alert("Payout txn id not found");
    }
    if (payment_gatway == "razorpay") {
      checkrazorpaypayout(txn_id, referenceId);
    } else if (payment_gatway == "decentro") {
      checkpayout(txn_id, referenceId);
    } else if (payment_gatway == "pinelab") {
      //checkPinelabpayout(txn_id, referenceId)
    } else {
      alert("Nothing found");
    }
  };

  const checkpayout = (txn_id, referenceId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `decentropayout/response`,
        { txn_id, referenceId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "SUCCESS" ? "success" : "danger";
        const title =
          res.data.status == "SUCCESS"
            ? "Withdraw successfully"
            : "Transaction Proccessing or Failed";

        profle();
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: "OK",
        });
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const checkrazorpaypayout = (txn_id, referenceId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaypayoutscheck/response`,
        { txn_id, referenceId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "SUCCESS" ? "success" : "danger";
        const title =
          res.data.status == "SUCCESS"
            ? "Withdraw successfully"
            : "Transaction Proccessing or Failed";

        profle();
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: "OK",
        });
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };

  let currentTime = Date.now();

  useEffect(() => {
    profle();
  }, [pageNumber, limit, searchList, searchType, findByStatus]);

  if (user == undefined) {
    return null;
  }

  const handleButtonClick = (status) => {
    setActiveButton(status);
    setFindByStatus(status);
  };

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Withdrawal History</h4>

            {/* searching */}

            {/* <div className="btn-group" role="group">
                                <button type="button" className={`btn btn-outline-primary ${activeButton === 'Pending' ? 'active' : ''}`} onClick={() => handleButtonClick('Pending')}>Pending</button>
                                <button type="button" className={`btn btn-outline-success ${activeButton === 'Success' ? 'active' : ''}`} onClick={() => handleButtonClick('SUCCESS')}>Success</button>

                                <button type="button" className={`btn btn-outline-danger ${activeButton === 'Reject' ? 'active' : ''}`} onClick={() => handleButtonClick('reject')}>Reject</button>
                      </div> */}

            <div className="btn-group d-flex justify-content-between mb-3" role="group">
              <button
                type="button"
                className={`btn btn-outline-primary btn-lg mr-5 ${
                  activeButton === "Pending" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Pending")}
              >
                Pending
              </button>
              <button
                type="button"
                className={`btn btn-outline-success btn-lg ${
                  activeButton === "Success" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("SUCCESS")}
              >
                Success
              </button>
              <button
                type="button"
                className={`btn btn-outline-danger btn-lg ml-5 ${
                  activeButton === "Reject" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("FAILED")}
              >
                Reject
              </button>
            </div>

            <div className="row align-items-center">
              <select
                className="form-control col-sm-3 m-2"
                id="searchType"
                name="searchtype"
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="0">Select Search by</option>
                <option value="Name">Name</option>
                <option value="Phone">Phone</option>
              </select>
              <input
                type="text"
                placeholder="Search..."
                className="form-control col-sm-4 m-2"
                onChange={searchHandler}
              />
              <h5>Or</h5>
              <select
                className="form-control col-sm-2 m-2"
                id="findByStatus"
                name="findByStatus"
                onChange={searchByStatus}
              >
                <option value="Pending">Search Status</option>
                <option value="SUCCESS">Success</option>
                <option value="Pending">Pending</option>
                {/* <option value="pending">pending</option> */}
                <option value="none">Proccessing</option>
                <option value="reject">Reject</option>
                <option value="FAILED">Failed</option>
              </select>
              <select
                className="form-control col-sm-2 m-1"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>

            <div className="table-responsive">
            <table className="table table-striped table-bordered">
            <thead className="table-primary">
                  <tr>
                    <th>#</th>
                    <th>ID</th>
                    <th>Phone</th>
                    <th>User</th>
                    <th>Type</th>
                    <th>Paytm Number</th>
                    <th>Amount</th>
                    <th>Gateway Reference Id</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Remark</th>
                    <th>Accepetd By</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {user &&
                    user.map((data, key) => {
                      const isClicked = clickedUsers.includes(data._id);
                      const isPaymentStatusButtonVisible =
                        isClicked && data.status === "SUCCESS"; // Check if "Check Payment Status" button should be visible
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{data._id}</td>
                          <td>
                            <span className="pl-2">
                              {data.User_id ? data.User_id.Phone : ""}
                            </span>
                          </td>
                          <td>
                            {data.User_id && (
                              <Link
                                className="btn btn-sm btn-outline-info"
                                to={`/user/view_user/${data.User_id._id}`}
                              >
                                {data.User_id.Name}
                              </Link>
                            )}
                          </td>
                          <td>
                            <span className="pl-2">{data.Withdraw_type}</span>
                          </td>
                          <td>
                            <span className="pl-2">
                              {(data.User_id && data.User_id.upi_id) || ""}
                            </span>
                          </td>
                          <td>{data.amount}</td>
                          <td>{data.referenceId}</td>
                          <td className="font-weight-bold text-success">
                            {data.status === "none"
                              ? "Proccessing"
                              : data.status}
                          </td>

                          <td>
                            {clickedUsers &&
                            data.status !== "SUCCESS" &&
                            data.status !== "FAILED" &&
                            data.status !== "reject" ? (
                              <button className="btn btn-sm btn-primary mr-1">
                                Check Payment Status
                              </button>
                            ) : null}

                            {data.status !== "SUCCESS" &&
                            data.status !== "FAILED" &&
                            data.status !== "reject" &&
                            (data.referenceId === null ||
                              data.referenceId === undefined) ? (
                              <button
                                className="mr-1 btn btn-sm btn-danger"
                                onClick={() => withdrowFail(data._id)}
                              >
                                Fail
                              </button>
                            ) : (
                              ""
                            )}

                            {data.status !== "SUCCESS" &&
                              data.status !== "FAILED" &&
                              data.status !== "reject" &&
                              !isClicked &&
                              (data.referenceId === null ||
                                data.referenceId === undefined) && (
                                <button
                                  className="ml-1 btn btn-sm btn-warning"
                                  onClick={() => withdrowSuccess(data._id)}
                                >
                                  Success
                                </button>
                              )}
                            {data.status !== "SUCCESS" &&
                              data.status !== "FAILED" &&
                              data.status !== "reject" &&
                              isClicked &&
                              (data.referenceId === null ||
                                data.referenceId === undefined) && (
                                <button
                                  className="ml-1 btn btn-sm btn-warning"
                                  disabled
                                >
                                  Success
                                </button>
                              )}
                          </td>
                          <td style={{ color: "red" }}>{data.txn_msg}</td>
                          <td style={{ color: "red" }}>{data.Name}</td>
                          <td>{dateFormat(data.createdAt).split(",")[0]}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SucWithdraw;
