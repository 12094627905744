import React, { useState, useEffect } from "react";

import axios from "axios";

const Gateway = () => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [RazorDeposit, setRazorDeposit] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState();
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState();
  const [AccountName, setAccountName] = useState();
  const [PhoneStatus, setPhoneStatus] = useState(true);
  const [PhoneMID, setPhoneMID] = useState(true);
  const [PhonePeKey, setPhonePeKey] = useState(false);
  const [UpiGateway, setUpiGateway] = useState(false);
  const [autoPayout, setAutoPayout] = useState(false);
  const [autoPayoutBy, setAutoPayoutBy] = useState("");
  const [UpiGatewayKey, setUpiGatewayKey] = useState("");
  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    const data = axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setRazorpayout(res.data.RazorPayout);
      setRazorDeposit(res.data.RazorDeposit);
      setRazorpayAuto(res.data.RazorpayAuto);
      setPhoneStatus(res.data.PhonePeKey);
      setPhoneMID(res.data.PhoneMID);
      setPhonePeKey(res.data.PhonePeKey);
      setRazorpayKey(res.data.RazorPayKey);
      setRazorpaysecretKey(res.data.RazorPaySecretKey);
      setAccountName(res.data.AccountName);
      setUpiGateway(res.data.UpiGateway);
      setUpiGatewayKey(res.data.UpiGatewayKey);
      setAutoPayoutBy(res.data.autoPayoutBy);
      setAutoPayout(res.data.autoPayout);
    });
  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const response = await axios.post(baseUrl + `gatewaysettings`, {
      settingId,
      RazorPayout,
      RazorDeposit,
      RazorpayAuto,
      PhoneStatus,
      PhoneMID,
      PhonePeKey,
      RazorPayKey,
      RazorPaySecretKey,
      AccountName,
      UpiGateway,
      UpiGatewayKey,
      autoPayout,
      autoPayoutBy,
    });
    console.log(response.data.status);
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3">
        Payment Gateway Settings
      </h4>

      <h5 className="text-uppercase font-weight-bold my-3">RazorPay</h5>

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        enctype="multipart/form-date"
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" >
              Razorpay Payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" >
              Razorpay Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorDeposit}
              onChange={(e) => setRazorDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazauto" >
              RazorPay Auto
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="RazorpayKey">RazorPay Key</label>
            <input
              className="form-control"
              type="text"
              value={RazorPayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="RazorpaysecretKey">RazorPay SecretKey</label>
            <input
              className="form-control"
              type="text"
              value={RazorPaySecretKey}
              onChange={(e) => setRazorpaysecretKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="AccountName">Account Name</label>
            <input
              className="form-control"
              type="text"
              value={AccountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>
        </div>

        <h5 className="text-uppercase font-weight-bold my-3">PhonePE</h5>
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="buttondecpay" >
              Status
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={PhoneStatus}
              onChange={(e) => setPhoneStatus(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttondecdep" >
              MID
            </label>
            <input
              className="form-control"
              type="text"
              value={PhoneMID}
              onChange={(e) => setPhoneMID(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttondecdep" >
              KEY
            </label>
            <input
              className="form-control"
              type="text"
              value={PhonePeKey}
              onChange={(e) => setPhonePeKey(e.target.value)}
            />
          </div>
        </div>

        <h5 className="text-uppercase font-weight-bold my-3">
          UPI Gateway
        </h5>
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="buttondecpay">
              Status
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={UpiGateway}
              onChange={(e) => setUpiGateway(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttondecdep" >
              KEY
            </label>
            <input
              className="form-control"
              type="text"
              value={UpiGatewayKey}
              onChange={(e) => setUpiGatewayKey(e.target.value)}
            />
          </div>
        </div>

        <h5 className="text-uppercase font-weight-bold my-3">
          Auto Payout
        </h5>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttondecpay" >
              Status
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={autoPayout}
              onChange={(e) => setAutoPayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttondecpay" >
              Payout by
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={autoPayoutBy}
              onChange={(e) => setAutoPayoutBy(e.target.value)}
            >
              <option value="mypay">MyPAY</option>
              <option value="haoda">HAODA</option>
            </select>


          </div>
          <div className="form-group col-md-4 d-flex align-items-end">
            <button type="submit" className="btn btn-dark">
              submit
            </button>
          </div>
        </div>


        <div className="form-row">

        </div>
      </form>
    </>
  );
};

export default Gateway;
